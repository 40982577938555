
$(window).resize(function() {
  var more = document.getElementById("js-navigation-more");
  if ($(more).length > 0) {
    var windowWidth = $(window).width();
    var moreLeftSideToPageLeftSide = $(more).offset().left;
    var moreLeftSideToPageRightSide = windowWidth - moreLeftSideToPageLeftSide;

    if (moreLeftSideToPageRightSide < 330) {
      $("#js-navigation-more .submenu .submenu").removeClass("fly-out-right");
      $("#js-navigation-more .submenu .submenu").addClass("fly-out-left");
    }

    if (moreLeftSideToPageRightSide > 330) {
      $("#js-navigation-more .submenu .submenu").removeClass("fly-out-left");
      $("#js-navigation-more .submenu .submenu").addClass("fly-out-right");
    }
  }
});


$(document).ready(function() {
  var menuToggle = $("#js-mobile-menu").unbind();
  $("#js-navigation-menu").removeClass("show");

  menuToggle.on("click", function(e) {
    e.preventDefault();
    $("#js-navigation-menu").slideToggle(function(){
      if($("#js-navigation-menu").is(":hidden")) {
        $("#js-navigation-menu").removeAttr("style");
      }
    });
  });
});

// magnific Lightbox
$(document).ready(function() {
    $('.magnific, div.imageholder a').magnificPopup({
      type: 'image',
      // other options
      gallery: {
			  enabled: true,
			  navigateByImgClick: true,
			  preload: [0,1] // Will preload 0 - before current, and 1 after the current image
		  },
    });

    $('.open-popup-video').magnificPopup({
      type:'inline',
      closeBtnInside: true,
      midClick: true // Allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source in href.
    });
  });




//jQuery to collapse the navbar on scroll
$(window).scroll(function() {
  if ($(".navigation").offset().top > 50) {
    $(".navigation").addClass("top-nav-collapse");
  } else {
    $(".navigation").removeClass("top-nav-collapse");
  }
});
